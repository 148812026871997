import React from 'react';

const Logo = () => {
    return (
        <svg width="91px" height="55px" viewBox="0 0 91 55" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-4">
                    <polygon fill="#8D1913" points="0 54.0928 63.629 54.0928 45.429 6.8078"></polygon>
                    <polygon fill="#E42324" points="80.8128 0.0001 -0.0002 54.0931 90.3048 54.0931"></polygon>
                    <polygon fill="#C61718" points="0 54.0928 63.623 54.0928 50.594 20.2268"></polygon>
                </g>
            </g>
        </svg>
    )
}

export default Logo