import React from 'react'
import Header from './header'
import HeaderV2 from './headerV2/header'
import Footer from './footer/footer'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({
  children,
  page,
  companyInfo,
  announcement,
  logoOverrideUrl,
  onVisibleNav,
  onHideNav,
  onShowNav,
  showNav,
  siteData,
  headerNavItems,
  headerExtNavItems,
  footerNavItems,
  siteTitle,
  palette,
  hideContactBar,
  hideAnnouncements,
  hideColor,
  version,
  darkMode
}) => (
  <>
    {version === '2' ? (
      <HeaderV2 palette={palette} page={page} siteTitle={siteTitle} navItems={headerNavItems} extNavItems={headerExtNavItems} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} logoOverrideUrl={logoOverrideUrl} />
    ) : (
      <Header palette={palette} siteData={siteData} page={page} siteTitle={siteTitle} navItems={headerNavItems} extNavItems={headerExtNavItems} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} onVisibleNav={onVisibleNav} />
    )}
    <div className={styles.content}>{children}</div>
    {/* {!hideContactBar && */}
    {/*  ( */}
    {/*    <div className={`${styles.contactBlock} ${styles[palette]} ${hideColor ? styles.hideColor : ''}`}> */}
    {/*      <Container> */}
    {/*        <h2 className={`${styles.headline} ${styles.bullet}`}>Let's work together</h2> */}
    {/*        <Link to='/contact-us/' className={`${styles.button} ${styles.primary}`}>Contact us</Link> */}
    {/*      </Container> */}
    {/*    </div> */}
    {/*  )} */}
    {/* {version === '2' ? ( */}
    <Footer footerNavItems={footerNavItems} palette={palette} />
    {/* ) : (
      <footer className={styles.footer}>
        <Container>
          <div className={styles.topBlock}>
            <div className={styles.menuBlock}>
              {footerNavItems &&
                footerNavItems.map((link, index) => {
                  if (link.blank) {
                    return (
                      <a key={index} href={link.path} target="_blank">{link.label}</a>
                    )
                  } else if (link.path.startsWith('http')) {
                    return (
                      <a key={index} href={link.path}>{link.label}</a>
                    )
                  } else {
                    return (
                      <Link key={index} to={link.path}>{link.label}</Link>
                    )
                  }
                })}
            </div>
            <div className={styles.socials}>
              <a href="http://www.linkedin.com/company/redpointventures/" target="_blank"><Icon symbol='linkedin' /></a>
              <a href="https://www.instagram.com/redpoint_ventures/" target="_blank"><Icon symbol='instagram' /></a>
              <a href="https://medium.com/redpoint-ventures/" target="_blank"><Icon symbol='medium' /></a>
              <a href="http://www.twitter.com/redpointvc" target="_blank"><Icon symbol='twitter' /></a>
            </div>
            <div className={styles.logo}>
              {companyInfo && (
                <Link to='/' className={styles.homeLogo}>{companyInfo.name}</Link>
              )}
            </div>
          </div>
          <div className={styles.bottomBlock}>
            <div><Link to='/privacy-policy/'>Privacy Policy.</Link></div>
            {companyInfo && (
              <div>&copy; {new Date().getFullYear()} {companyInfo.name}.</div>
            )}
          </div>
        </Container>
      </footer>
    )} */}
  </>
)

export default Layout
