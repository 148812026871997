import React, { useState } from 'react'

import styles from './footer.module.css'
import Icon from '../icons'
import * as linkStyle from '../../styles/components/links.module.css'
import successIcon from './../../img/close.png'
import queryString from 'query-string'
import jsonp from 'jsonp'

const FooterContactForm = ({ palette }) => {
  const [email, setEmail] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const action = 'https://redpoint.us9.list-manage.com/subscribe/post-json?u=303bf0d309203a3ab69ac6668&id=6aca60fec3&f_id=00a704e1f0'

  const submit = async (e) => {
    e.preventDefault()

    const data = {
      EMAIL: email,
      b_303bf0d309203a3ab69ac6668_6aca60fec3: ''
    }

    jsonp(`${action}&${queryString.stringify(data)}`, { param: 'c' }, (err, data) => {
      if (data.result === 'success') {
        setSuccess(data.msg)
      } else if (data.result === 'error') {
        setError(data.msg)
      }
    })
  }

  return (
    <div className={`${styles.formWrapper} ${styles[palette]}`}>
      <h2>Join the Redpoint network</h2>
      {success ? (
        <div className={styles.successMessage}>
          <img src={successIcon} alt='Success' />
          <span>Thank you for subscribing!</span>
        </div>
      ) : (
        <>
          <form
            id='subscribe'
            className={styles.formContainer}
            onSubmit={submit}
          >
            <input
              type='email'
              id='mc-embedded-subscribe-form'
              name='mc-embedded-subscribe-form'
              placeholder='Email'
              aria-label='Enter your email'
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden='true'>
              <input
                type='text'
                name='b_303bf0d309203a3ab69ac6668_6aca60fec3'
                tabIndex='-1'
                value=''
                readOnly
              />
            </div>
            <button
              type='submit'
              className={`${linkStyle.linkArrow} ${linkStyle.submit} ${linkStyle.white}`}
            >
              Submit
              <span>
                <div className={`${linkStyle.leftArrow} ${linkStyle.iconRed}`}>
                  <Icon symbol='rightArrowIcon' color='currentColor' />
                  <div className={linkStyle.rightArrowCircle} />
                </div>
                <div className={`${linkStyle.rightArrow} ${linkStyle.iconRed}`}>
                  <Icon symbol='rightArrowIcon' color='currentColor' />
                </div>
              </span>
            </button>
          </form>
          {error && (
            <div style={{ color: '#fff' }} className={styles.error}>
              {error}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default FooterContactForm
