import React from 'react'

const TickIcon = () => (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.78165 13.2794L21.7563 0L25 3.40081L8.78165 20L0 11.0121L3.32278 7.61134L8.78165 13.2794Z" fill="currentColor"/>
    </svg>
)

export default TickIcon


