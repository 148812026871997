import React from 'react';

const ArrowRight = () => {
    return (
        <svg width="37" height="21" viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.5645 0.293015C27.174 -0.097509 26.5408 -0.097509 26.1503 0.293015C25.7598 0.68354 25.7598 1.3167 26.1503 1.70723L27.5645 0.293015ZM26.1503 18.5827C25.7598 18.9732 25.7598 19.6064 26.1503 19.9969C26.5408 20.3874 27.174 20.3874 27.5645 19.9969L26.1503 18.5827ZM26.1503 1.70723L33.8809 9.43784L35.2951 8.02363L27.5645 0.293015L26.1503 1.70723ZM33.8809 10.8521L26.1503 18.5827L27.5645 19.9969L35.2951 12.2663L33.8809 10.8521ZM33.8809 9.43784C34.2715 9.82837 34.2715 10.4615 33.8809 10.8521L35.2951 12.2663C36.4667 11.0947 36.4667 9.1952 35.2951 8.02363L33.8809 9.43784Z" fill="currentColor"/>
            <line x1="34.2988" y1="10.4415" x2="1.12642" y2="10.4415" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default ArrowRight