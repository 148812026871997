import React from 'react';

const ArrowIcon = () => {
    return (
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.43842 7.46978C0.145527 7.76267 0.145527 8.23755 0.43842 8.53044C0.731313 8.82333 1.20619 8.82333 1.49908 8.53044L0.43842 7.46978ZM7.86272 2.1668C8.15561 1.87391 8.15561 1.39904 7.86272 1.10614C7.56982 0.813251 7.09495 0.813251 6.80206 1.10614L7.86272 2.1668ZM1.49908 8.53044L7.86272 2.1668L6.80206 1.10614L0.43842 7.46978L1.49908 8.53044Z" fill="currentColor"/>
            <path d="M2.87695 0.25C2.46274 0.25 2.12695 0.585786 2.12695 1C2.12695 1.41421 2.46274 1.75 2.87695 1.75V0.25ZM7.96786 1H8.71786C8.71786 0.585786 8.38208 0.25 7.96786 0.25V1ZM7.21786 6.09091C7.21786 6.50512 7.55365 6.84091 7.96786 6.84091C8.38208 6.84091 8.71786 6.50512 8.71786 6.09091H7.21786ZM2.87695 1.75H7.96786V0.25H2.87695V1.75ZM7.21786 1V6.09091H8.71786V1H7.21786Z" fill="currentColor"/>
        </svg>
    )
}

export default ArrowIcon