import React from 'react'

const ShareLink = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2602 9.00005H9.73759C9.33493 8.99585 9.00491 9.31847 9 9.7211V18.2783C9.00462 18.6811 9.33473 19.0041 9.73759 18.9999H18.2602C18.6636 19.0044 18.9945 18.6816 19 18.2783V9.7211C18.9945 9.31786 18.6635 8.99523 18.2602 9.00005ZM11.9663 17.5208H10.4814V12.7489H11.9664L11.9663 17.5208ZM11.2246 12.0969C10.8767 12.0971 10.5629 11.8877 10.4295 11.5664C10.2962 11.245 10.3696 10.875 10.6156 10.6289C10.8615 10.3828 11.2314 10.3091 11.5529 10.4421C11.8744 10.5752 12.0841 10.8888 12.0841 11.2367C12.0843 11.4647 11.9938 11.6835 11.8326 11.8448C11.6714 12.0062 11.4527 12.0968 11.2246 12.0969V12.0969ZM17.5217 17.5209H16.0388V15.2003C16.0388 14.6469 16.0293 13.9349 15.2681 13.9349C14.4963 13.9349 14.3786 14.5381 14.3786 15.1607V17.5209H12.8969V12.7489H14.3186V13.4015H14.3389C14.6288 12.9054 15.1681 12.6092 15.7422 12.6306C17.244 12.6306 17.5215 13.6187 17.5215 14.9039L17.5217 17.5209Z" fill="#141414"/>
    </svg>

)

export default ShareLink
