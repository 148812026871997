import React from 'react'

const TeamBlockGrowthIcon = () => (
    <svg width="700" height="380" viewBox="0 0 700 380" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="700" height="380" fill="#96B9A4"/>
        <path d="M700 37.8298C493.043 -12.8631 284.565 239.699 2.39725e-05 105.787C0.586009 263.987 0.231767 193.309 0.58606 380L700 380L700 37.8298Z" fill="#90B49E"/>
        <path d="M700 263.438C493.043 248.234 90 373 5.39993e-06 318.232C0.586 365.678 0.231755 324.009 0.58606 380L700 380L700 263.438Z" fill="#79A28A"/>
    </svg>
)

export default TeamBlockGrowthIcon
