import React from 'react'

const Apple = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd' clipRule='evenodd'
        d='M15.45 1C15.45 1 13.7266 1.00883 12.4868 2.67059C11.3846 4.14796 11.6651 5.4113 11.6651 5.4113C11.6651 5.4113 13.16 5.67482 14.4749 4.06727C15.7063 2.56248 15.45 1 15.45 1ZM12.8112 6.01612C12.4055 6.18246 12.0721 6.31915 11.8224 6.31915C11.5104 6.31915 11.1342 6.1717 10.6972 6.00043C10.0923 5.76335 9.37097 5.48063 8.54211 5.48008C6.3439 5.48008 4 7.4832 4 11.2972C4 15.1754 6.76761 20 8.95932 20C9.30022 20 9.7225 19.8444 10.1907 19.6719C10.7731 19.4573 11.4265 19.2165 12.0828 19.2165C12.671 19.2165 13.1812 19.4105 13.6847 19.602C14.1753 19.7887 14.6596 19.9729 15.2037 19.9729C17.6037 19.9729 19.381 14.8868 19.381 14.8868C19.381 14.8868 16.8393 13.886 16.8393 10.982C16.8393 8.40739 18.8798 7.34672 18.8798 7.34672C18.8798 7.34672 17.8365 5.42744 15.1845 5.42744C14.2469 5.42744 13.4417 5.75759 12.8112 6.01612Z'
        fill='currentColor'
      />
    </svg>

  )
}

export default Apple
