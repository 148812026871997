import React from 'react'

const Spotify = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.9323 3C6.99875 3 3 6.99875 3 11.9323C3 16.8657 6.99875 20.8645 11.9323 20.8645C16.8658 20.8645 20.8646 16.8657 20.8646 11.9323C20.8646 6.99875 16.8658 3 11.9323 3ZM16.0423 15.9088C15.8643 16.1759 15.5527 16.2649 15.2856 16.0868C13.1861 14.7885 10.5524 14.5215 7.42162 15.2411C7.11003 15.3301 6.84296 15.1075 6.75393 14.8405C6.6649 14.5289 6.88747 14.2618 7.15454 14.1728C10.5524 13.4161 13.4977 13.7276 15.8197 15.152C16.1313 15.2856 16.1758 15.6417 16.0423 15.9088ZM17.118 13.4531C16.8954 13.7647 16.4949 13.8983 16.1833 13.6757C13.7722 12.1994 10.1073 11.7542 7.29551 12.6445C6.9394 12.7335 6.53879 12.5555 6.44975 12.1994C6.36073 11.8432 6.53879 11.4426 6.89489 11.3536C10.1592 10.3743 14.1802 10.864 16.9474 12.5629C17.2145 12.6964 17.3481 13.1416 17.1255 13.4531H17.118ZM17.2071 10.953C14.3508 9.25408 9.56567 9.07603 6.84296 9.92177C6.39782 10.0553 5.95269 9.78824 5.81174 9.38761C5.67819 8.94248 5.94528 8.49736 6.34589 8.3564C9.52116 7.42163 14.744 7.59968 18.0528 9.56567C18.4535 9.78824 18.587 10.3224 18.3645 10.7304C18.1419 11.042 17.6077 11.1756 17.1997 10.953H17.2071Z'
      fill='currentColor'
    />
  </svg>
)

export default Spotify
