import React from 'react'

const ShareLink = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path d="M14.333 13.3317C14.333 11.6706 12.7883 10 10.9044 10C10.6813 10 9.9457 10 9.76158 10C7.86803 10 6.33301 11.4917 6.33301 13.3317C6.33301 14.9173 7.47283 16.2443 8.99967 16.5809C9.24471 16.635 9.49971 16.6635 9.76158 16.6635" stroke="#141414" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.6665 13.3317C11.6665 14.9929 13.2112 16.6635 15.0951 16.6635C15.3182 16.6635 16.0538 16.6635 16.2379 16.6635C18.1315 16.6635 19.6665 15.1718 19.6665 13.3317C19.6665 11.7461 18.5267 10.4192 16.9998 10.0826C16.7548 10.0285 16.4998 10 16.2379 10" stroke="#141414" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

)

export default ShareLink
