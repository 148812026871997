import React from 'react'

const SubmitArrow = () => (
  <svg width='26' height='27' viewBox='0 0 26 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.9742 3.71008L23.9742 20.5918L21.8941 20.5918L21.9932 7.06214L8.07636 19.8388L6.41724 18.0656L20.6065 5.67759L6.76392 5.75046L6.76392 3.71008L23.9742 3.71008Z'
      fill='currentColor'
    />
  </svg>

)

export default SubmitArrow
