import React from 'react'

const NewChevronRight = () => {
  return (
    <svg width='24' height='26' viewBox='0 0 24 26' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 1L18 13L6 25' stroke='currentColor' strokeWidth='2.83189' />
    </svg>
  )
}

export default NewChevronRight
