import React from 'react'
import HamburgerIcon from './hamburger'
import CloseIcon from './close'
import ViewMoreIcon from './viewmore'
import ChevronDownIcon from './chevron-down'
import ChevronRightIcon from './chevron-right'
import SearchIcon from './search'
import InstagramIcon from './social-instagram'
import LinkedinIcon from './social-linkedin'
import MediumIcon from './social-medium'
import TwitterIcon from './social-twitter'
import QuoteMarksIcon from './quote-marks'
import TeamBlockEarlyIcon from './team-block-early'
import TeamBlockGrowthIcon from './team-block-growth'
import TickIcon from './tick'
import RPMark from './rp-mark'
import Wave from './wave'
import Play from './play'
import Pause from './pause'
import Logo from './logo'
import LogoFull from './logo-full'
import ArrowLeft from './arrow-left'
import ArrowRight from './arrow-right'
import ArrowIcon from './arrow-icon'
import PlayCircle from './play-circle'
import ShareClose from './share-close'
import ShareEmail from './share-email'
import ShareFacebook from './share-facebook'
import ShareLink from './share-link'
import ShareLinkedin from './share-linkedin'
import ShareTwitter from './share-twitter'
import Apple from './apple'
import Spotify from './spotify'
import YouTube from './youtube'
import ArrowIconTopRight from './arrow-icon-top-right'
import SubmitArrow from './submit-arrow'
import NewsArrowIcon from './news-arrow-icon'
import LinkedIn from './linked-in'
import NewChevronRight from './new-chevron-right'
import ArrowBack from './arrow-back'
import RightArrowIcon from './right-arrow-icon'

function Icon (props) {
  switch (props.symbol) {
    case 'hamburger':
      return <HamburgerIcon />
    case 'close':
      return <CloseIcon />
    case 'viewmore':
      return <ViewMoreIcon />
    case 'chevrondown':
      return <ChevronDownIcon />
    case 'chevronright':
      return <ChevronRightIcon />
    case 'search':
      return <SearchIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'linkedin':
      return <LinkedinIcon />
    case 'medium':
      return <MediumIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'quotemark':
      return <QuoteMarksIcon />
    case 'teamblockearly':
      return <TeamBlockEarlyIcon />
    case 'teamblockgrowth':
      return <TeamBlockGrowthIcon />
    case 'tick':
      return <TickIcon />
    case 'mark':
      return <RPMark />
    case 'wave':
      return <Wave />
    case 'play':
      return <Play />
    case 'pause':
      return <Pause />
    case 'playCircle':
      return <PlayCircle />
    case 'logo':
      return <Logo />
    case 'logoFull':
      return <LogoFull />
    case 'arrowLeft':
      return <ArrowLeft />
    case 'arrowRight':
      return <ArrowRight />
    case 'arrowIcon':
      return <ArrowIcon />
    case 'shareClose':
      return <ShareClose />
    case 'shareEmail':
      return <ShareEmail />
    case 'shareFacebook':
      return <ShareFacebook />
    case 'shareLink':
      return <ShareLink />
    case 'shareLinkedin':
      return <ShareLinkedin />
    case 'shareTwitter':
      return <ShareTwitter />
    case 'apple':
      return <Apple />
    case 'spotify':
      return <Spotify />
    case 'youtube':
      return <YouTube />
    case 'arrowTopRight':
      return <ArrowIconTopRight color={props.color} />
    case 'submitArrow':
      return <SubmitArrow />
    case 'newsArrowIcon':
      return <NewsArrowIcon width={props.width} color={props.color} />
    case 'rightArrowIcon':
      return <RightArrowIcon color={props.color} />
    case 'arrowBack':
      return <ArrowBack color={props.color} />
    case 'linkedIn':
      return <LinkedIn />
    case 'newChevronRight':
      return <NewChevronRight />
    default:
      return <span>Unknown icon: {props.symbol}</span>
  }
}

export default Icon
