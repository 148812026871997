import React from 'react'

const ViewMoreIcon = () => (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29 57C44.464 57 57 44.464 57 29C57 13.536 44.464 1 29 1C13.536 1 1 13.536 1 29C1 44.464 13.536 57 29 57Z" stroke="currentColor" strokeWidth="2"/>
        <path d="M30.0012 36.1858V20H28.0012V36.1858L21.1083 29.2929L19.7012 30.7071L29.0012 40L38.3012 30.7071L36.8941 29.2929L30.0012 36.1858Z" fill="currentColor"/>
    </svg>
)

export default ViewMoreIcon
