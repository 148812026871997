import React from 'react'

const LinkedIn = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='12' cy='12' r='12' fill='#E3E3D5' />
    <path
      fillRule='evenodd' clipRule='evenodd'
      d='M14.3117 15.2977L14.3428 12.5516L14.4228 10.515L13.24 11.7753L8.02764 16.9875L6.88603 15.8459L12.1135 10.6185L13.3643 9.44537L11.3175 9.53086L8.56934 9.55565L8.53302 7.96496L15.8595 8.01405L15.9086 15.3412L14.3117 15.2977Z'
      fill='black'
    />
  </svg>
)

export default LinkedIn
