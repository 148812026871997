import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Icon from '../icons'

import * as styles from './header.module.css'
import { isExternalUrl } from '../../lib/helpers'

const Header = ({ onHideNav, onShowNav, showNav, navItems, page, logoOverrideUrl }) => {
  const [moving, setMoving] = useState(false)

  useEffect(() => {
    const modify = () => {
      if (typeof window !== 'undefined') {
        if (window.pageYOffset > 1) {
          setMoving(true)
        } else {
          setMoving(false)
        }
      }
    }

    window.addEventListener('scroll', modify)
    return () => {
      window.removeEventListener('scroll', modify)
    }
  }, [])

  return (
    <header className={`${styles.header} ${styles[page]} ${moving ? styles.solidBg : ''}`}>
      <div className={styles.logo}>
        <Link to={logoOverrideUrl || '/'}><Icon symbol='mark' /></Link>
      </div>

      <div className={styles.navigationBlock} onClick={showNav ? onHideNav : onShowNav}>
        <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
          <Icon symbol='hamburger' />
          <Icon symbol='close' />
        </button>
      </div>

      <nav className={`${styles.nav} ${showNav && styles.showNav}`}>
        <ul>
          {navItems &&
                        navItems.map((link, index) => {
                          if (link.blank) {
                            return (
                              <li key={index}><a href={link.path} target='_blank'>{link.label}</a></li>
                            )
                          } else if (link.path.startsWith('http')) {
                            return (
                              <li key={index}><a target={isExternalUrl(link.path) ? '_blank' : '_self'} href={link.path} rel='noreferrer'>{link.label}</a></li>
                            )
                          } else {
                            return (
                              <li key={index}><Link target={isExternalUrl(link.path) ? '_blank' : '_self'} to={link.path}>{link.label}</Link></li>
                            )
                          }
                        })}
        </ul>
      </nav>
    </header>
  )
}

export default Header
