import React from 'react'

const Pause = () => (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 13.4306V0.63064C0 0.29927 0.26863 0.0306396 0.6 0.0306396H3.4C3.73137 0.0306396 4 0.29927 4 0.63064V13.4306C4 13.762 3.73137 14.0306 3.4 14.0306H0.6C0.26863 14.0306 0 13.762 0 13.4306Z" fill="white"/>
    <path d="M8 13.4306V0.63064C8 0.29927 8.2686 0.0306396 8.6 0.0306396H11.4C11.7314 0.0306396 12 0.29927 12 0.63064V13.4306C12 13.762 11.7314 14.0306 11.4 14.0306H8.6C8.2686 14.0306 8 13.762 8 13.4306Z" fill="white"/>
    </svg>


)

export default Pause
