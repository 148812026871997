import React from 'react'
import { SanityImage } from 'sanity-image'

const projectId = '22xmfoma'
const dataset = process.env.GATSBY_SANITY_DATASET || 'production'
const baseUrl = `https://cdn.sanity.io/images/${projectId}/${dataset}/`

export const Image = ({ props, imgObj = {}, onClick, className, style }) => {
  if (!imgObj || !imgObj.asset || !(imgObj.asset._id || imgObj.asset._ref)) return <img src='https://via.placeholder.com/450x500?text=Placeholder' className={className} alt='Image not found' />

  let params = props

  if ((imgObj && imgObj.asset && imgObj.asset._id)) {
    if (imgObj?.asset?._id.indexOf('-gif') === -1) {
      if (Object.keys(imgObj).length !== 0) {
        params = {
          htmlId: imgObj.asset._id || imgObj.asset._ref,
          id: imgObj.asset._id || imgObj.asset._ref,
          alt: imgObj.asset.alt,
          hotspot: imgObj.hotspot,
          mode: 'cover',
          ...props
        }
      }
    } else {
      if (Object.keys(imgObj).length !== 0) {
        params = {
          htmlId: imgObj.asset._id || imgObj.asset._ref,
          id: imgObj.asset._id || imgObj.asset._ref,
          alt: imgObj.asset.alt,
          hotspot: imgObj.hotspot,
          mode: 'cover',
          ...props
        }
      }
    }
  } else {
    if (Object.keys(imgObj).length !== 0) {
      params = {
        htmlId: imgObj.asset._id || imgObj.asset._ref,
        id: imgObj.asset._id || imgObj.asset._ref,
        alt: imgObj.asset.alt,
        hotspot: imgObj.hotspot,
        mode: 'cover',
        ...props
      }
    }
  }

  return (<SanityImage baseUrl={baseUrl} {...params} className={className} style={style} onClick={onClick} />)
}
