import React from 'react';

const LogoFull = () => {
    return (
        <svg width="190" height="34" viewBox="0 0 190 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.1057 27.2916L22.9149 3.42969L0 27.2916H32.1057Z" fill="#8D1B1C"/>
            <path d="M45.5504 27.293L40.7687 0L0 27.293H45.5504Z" fill="#FF0E1D"/>
            <path d="M25.5231 10.1992L0 27.2923H32.0902L25.5231 10.1992Z" fill="#C62127"/>
            <path d="M66.5406 18.0557L71.3999 25.1817L73.5113 25.8492V27.1999H67.9068L61.8365 17.7297H59.5699V25.1817L61.4329 25.8182V27.1999H53.4375V25.8492L55.3315 25.1817V7.21922L53.4375 6.55165V5.16992H61.8676C67.8447 5.16992 71.3378 7.15712 71.3378 11.4576C71.3378 14.5936 69.4593 16.4877 66.1214 17.2794L66.5406 18.0557ZM66.137 17.295L66.0438 17.326L66.5406 18.0712L66.5251 18.0557L66.137 17.295ZM59.5699 7.15713V15.7735H61.9297C65.4849 15.7735 67.0529 14.4228 67.0529 11.4576C67.0529 8.60095 65.4849 7.1416 61.9297 7.1416H59.5699V7.15713Z" fill="currentColor"/>
            <path d="M88.6026 19.7147V20.6618H77.5954C77.8127 23.0216 78.915 25.5366 82.2529 25.5366C84.5816 25.5366 86.0255 24.7759 87.1588 23.9686L88.0748 25.2882C87.1277 26.1421 85.3424 27.5238 81.7561 27.5238C76.5086 27.5238 73.6055 24.4654 73.6055 19.7147C73.6055 14.902 76.757 11.8125 81.2127 11.8125C85.715 11.8125 88.6026 14.8554 88.6026 19.7147ZM77.5643 18.7677H84.6127C84.5196 16.47 83.883 13.7686 81.2127 13.7686C78.6045 13.7686 77.7196 16.3148 77.5643 18.7677Z" fill="currentColor"/>
            <path d="M97.7921 12.078C99.9035 12.078 101.565 12.7766 102.574 14.6551V5.91454L100.68 5.27801V3.86523H106.533V25.445L108.427 26.0815V27.4632H102.698V24.9482C101.782 26.9043 100.152 27.7737 97.73 27.7737C93.3209 27.7737 90.3711 24.9171 90.3711 19.9336C90.3711 14.8104 93.4916 12.078 97.7921 12.078ZM98.5838 14.0496C95.6341 14.0496 94.5939 16.751 94.5939 19.9336C94.5939 22.8213 95.572 25.7865 98.5838 25.7865C101.735 25.7865 102.512 22.635 102.574 20.3062V19.9336C102.574 17.3254 101.891 14.0496 98.5838 14.0496Z" fill="currentColor"/>
            <path d="M111.267 31.9822V14.3303L109.668 13.6627V12.281H115.117V14.4856C116.095 12.6226 117.756 11.9395 119.899 11.9395C124.184 11.9395 127.32 14.7029 127.32 19.8417C127.32 24.996 124.137 27.6508 119.806 27.6508C117.694 27.6508 116.126 27.0298 115.117 25.5394V31.9822L117.85 32.6188V34.0005H109.357V32.6498L111.267 31.9822ZM119.107 25.648C121.995 25.648 123.097 22.9467 123.097 19.8572C123.097 16.7677 122.057 13.9111 119.076 13.9111C115.956 13.9111 115.179 16.9696 115.117 19.3915V19.7641C115.102 22.2015 115.738 25.648 119.107 25.648Z" fill="currentColor"/>
            <path d="M136.9 27.5551C132.025 27.5551 128.656 24.4345 128.656 19.6218C128.656 14.8401 132.056 11.8438 136.9 11.8438C141.775 11.8438 145.113 14.8401 145.113 19.6218C145.113 24.3724 141.806 27.5551 136.9 27.5551ZM136.9 25.7542C139.958 25.7542 140.859 22.4163 140.859 19.6218C140.859 16.8273 139.943 13.6447 136.9 13.6447C133.842 13.6447 132.91 16.8273 132.91 19.6218C132.895 22.4318 133.842 25.7542 136.9 25.7542Z" fill="currentColor"/>
            <path d="M147.316 25.2269V14.2508L145.717 13.5832V12.2015H151.166V25.2269L153.091 25.8635V27.2452H145.406V25.8945L147.316 25.2269ZM149.225 4.36133C150.887 4.36133 151.74 5.5257 151.74 6.87638C151.74 8.22705 150.887 9.36038 149.225 9.36038C147.595 9.36038 146.71 8.22705 146.71 6.87638C146.71 5.5257 147.595 4.36133 149.225 4.36133Z" fill="currentColor"/>
            <path d="M160.482 25.1953L162.408 25.8318V27.2446H154.723V25.8628L156.648 25.1953V14.2501L155.049 13.5826V12.2008H160.498V14.9332C160.933 13.1323 163.106 11.8438 165.404 11.8438C168.524 11.8438 170.822 13.6447 170.822 17.5725V25.1797L173.089 25.8163V27.229H166.987V17.293C166.987 15.1816 166.009 14.0794 164.348 14.0794C162.175 14.0794 160.576 15.9113 160.513 18.6437V25.1953H160.482Z" fill="currentColor"/>
            <path d="M178.15 12.1573H182.046V13.9582H178.15V21.9536C178.15 25.1362 179.19 25.4778 180.137 25.4778C180.804 25.4778 181.519 25.2915 182.279 24.8412L182.947 26.1609C181.845 26.8905 180.711 27.3563 179.112 27.3563C175.899 27.3563 174.331 25.5243 174.331 21.6896V13.9582H172.281V12.1573H174.331L176.473 8.07422H178.165V12.1573H178.15Z" fill="currentColor"/>
            <path d="M187.031 22.3711C188.692 22.3711 189.546 23.5355 189.546 24.8861C189.546 26.2368 188.692 27.3701 187.031 27.3701C185.401 27.3701 184.516 26.2368 184.516 24.8861C184.516 23.5355 185.401 22.3711 187.031 22.3711Z" fill="#2B3A41"/>
        </svg>

    )
}

export default LogoFull