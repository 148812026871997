import React from 'react'

const SearchIcon = () => (
  <svg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='5.48571' cy='5.98571' r='4.88571' stroke='currentColor' strokeWidth='1.2' />
    <path d='M8.91431 9.41428L12 12.5' stroke='currentColor' />
  </svg>

)

export default SearchIcon
