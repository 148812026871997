import React from 'react'

const HamburgerIcon = () => (
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_1470_930)'>
      <rect x='0.871582' y='19' width='32' height='1' fill='currentColor' />
      <rect x='0.871582' y='11' width='32' height='1' fill='currentColor' />
    </g>
    <defs>
      <clipPath id='clip0_1470_930'>
        <rect width='32' height='32' fill='white' transform='translate(0.850098)' />
      </clipPath>
    </defs>
  </svg>
)

export default HamburgerIcon
