import React from 'react'

const ShareClose = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.505368 11.505L0.0104017 12L1.00037 12.9899L1.49533 12.495L0.505368 11.505ZM6.50018 6.5L6.99516 6.00503C6.86388 5.87375 6.68583 5.8 6.50018 5.8C6.31452 5.8 6.13647 5.87375 6.00519 6.00503L6.50018 6.5ZM11.505 12.495L12 12.9899L12.9899 12L12.495 11.505L11.505 12.495ZM1.49533 12.495L6.99516 6.99497L6.00519 6.00503L0.505368 11.505L1.49533 12.495ZM6.00519 6.99497L11.505 12.495L12.495 11.505L6.99516 6.00503L6.00519 6.99497Z" fill="white"/>
        <path d="M12.4946 1.49497L12.9896 0.999984L11.9996 0.0100503L11.5047 0.505033L12.4946 1.49497ZM6.49982 6.5L6.00484 6.99497C6.13612 7.12625 6.31417 7.2 6.49982 7.2C6.68548 7.2 6.86353 7.12625 6.99481 6.99497L6.49982 6.5ZM1.49498 0.505033L1.00002 0.0100503L0.0100505 0.999984L0.505017 1.49497L1.49498 0.505033ZM11.5047 0.505033L6.00484 6.00503L6.99481 6.99497L12.4946 1.49497L11.5047 0.505033ZM6.99481 6.00503L1.49498 0.505033L0.505017 1.49497L6.00484 6.99497L6.99481 6.00503Z" fill="white"/>
    </svg>

)

export default ShareClose
