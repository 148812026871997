import React from 'react'

const NewsArrowIcon = ({ color = '#B5B0A9', width }) => {
  return (
    <svg width={width ? width : '16'} height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16 -6.99382e-07L16 16L14.0662 16L14.1583 3.17698L1.54245 16L0 14.3194L12.8691 1.86475L-6.14852e-07 1.93382L-6.99382e-07 0L16 -6.99382e-07Z'
        fill={color}
      />
    </svg>

  )
}

export default NewsArrowIcon
