import React from 'react'

import styles from './container.module.css'
import { cn } from '../lib/helpers'

const Container = (props) => {
  const size = 'size' in props ? props.size : 'normal'
  const height = props.height

  return <div className={cn(styles.root, styles[size], styles[height], props.class)} style={props.style}>{props.children}</div>
}

export default Container
