import React from 'react'

const TeamBlockEarlyIcon = () => (
    <svg width="700" height="380" viewBox="0 0 700 380" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="700" height="380" fill="#D8A158"/>
        <path d="M700 37.8298C493.043 -12.8631 284.565 239.699 2.39725e-05 105.787C0.586009 263.987 0.231767 193.309 0.58606 380L700 380L700 37.8298Z" fill="#D09950"/>
        <path d="M-2.80313e-06 347.936C82.1072 265.658 629.067 324 700 268C699.414 332.615 699.768 303.748 699.414 380L6.83363e-06 380L-2.80313e-06 347.936Z" fill="#C5924E"/>
    </svg>
)

export default TeamBlockEarlyIcon
