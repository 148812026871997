import React from 'react';

const ArrowBack = ({ color = "#FF0E1D" }) => {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.428734 0.322308L0.428733 11.3416L1.78649 11.3416L1.72183 2.51031V2.51031C5.30892 4.82729 8.45261 7.84333 10.9016 11.3416V11.3416L13.1055 11.3416V11.3416C10.5373 7.28321 6.96941 3.95278 2.74401 1.66979L2.627 1.60657L11.6625 1.65414L11.6625 0.322309L0.428734 0.322308Z" fill={color} />
        </svg>
    )
}

export default ArrowBack