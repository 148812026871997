import React from 'react'

const ChevronDownIcon = () => (
  <svg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M9 1.28566L5 4.71423L1 1.28566' stroke='black' />
  </svg>

)

export default ChevronDownIcon
