import React from 'react';

const ArrowLeft = () => {
    return (
        <svg width="37" height="21" viewBox="0 0 37 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.43742 19.9968C9.82795 20.3873 10.4611 20.3873 10.8516 19.9968C11.2422 19.6063 11.2422 18.9731 10.8516 18.5826L9.43742 19.9968ZM10.8516 1.70712C11.2422 1.3166 11.2422 0.683436 10.8516 0.29291C10.4611 -0.0976143 9.82795 -0.0976143 9.43743 0.29291L10.8516 1.70712ZM10.8516 18.5826L3.12102 10.852L1.70681 12.2662L9.43742 19.9968L10.8516 18.5826ZM3.12102 9.43774L10.8516 1.70712L9.43743 0.29291L1.70681 8.02352L3.12102 9.43774ZM3.12102 10.852C2.7305 10.4614 2.7305 9.82826 3.12102 9.43774L1.70681 8.02352C0.535238 9.1951 0.535238 11.0946 1.70681 12.2662L3.12102 10.852Z" fill="currentColor"/>
            <line x1="2.70313" y1="9.84839" x2="35.8755" y2="9.84839" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

export default ArrowLeft