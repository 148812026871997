import React from 'react'

const YouTube = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.3221 7.34014C22.0712 6.414 21.3436 5.6946 20.4152 5.44653C18.7339 5 11.9926 5 11.9926 5C11.9926 5 5.25119 5 3.57002 5.44653C2.64998 5.6946 1.91394 6.42227 1.67139 7.34014C1.21973 9.01048 1.21973 12.5 1.21973 12.5C1.21973 12.5 1.21973 15.9895 1.67139 17.6599C1.92231 18.586 2.64998 19.3054 3.57839 19.5535C5.25956 20 12.001 20 12.001 20C12.001 20 18.7424 20 20.4236 19.5535C21.3519 19.3054 22.0797 18.5777 22.3306 17.6599C22.7822 15.9895 22.7822 12.5 22.7822 12.5C22.7822 12.5 22.7822 9.01048 22.3306 7.34014H22.3221ZM9.79287 15.6753V9.34124L15.4219 12.5083L9.79287 15.6753Z'
      fill='currentColor'
    />
  </svg>
)

export default YouTube
