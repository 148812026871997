import React from 'react'

const ShareTwitter = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M20 9.65262C19.5507 9.8517 19.0741 9.98236 18.5861 10.0402C19.1001 9.73258 19.4848 9.24855 19.6684 8.67831C19.1852 8.96506 18.6566 9.16709 18.1053 9.27567C17.342 8.46454 16.1296 8.26614 15.1476 8.79171C14.1657 9.31728 13.6583 10.4361 13.91 11.521C11.9302 11.422 10.0856 10.487 8.83542 8.94885C8.18212 10.0738 8.5157 11.5128 9.59734 12.2356C9.2064 12.2233 8.82405 12.1178 8.48221 11.9277V11.9587C8.48233 13.1306 9.30817 14.1401 10.4568 14.3725C10.0943 14.4711 9.71401 14.4856 9.34503 14.4148C9.66741 15.4176 10.5916 16.1046 11.6448 16.1244C10.7728 16.8088 9.69587 17.18 8.58729 17.1783C8.39102 17.1783 8.19492 17.1668 8 17.1438C9.12561 17.8671 10.4357 18.2511 11.7737 18.2498C13.6354 18.2627 15.4246 17.5289 16.7411 16.2125C18.0576 14.8962 18.7915 13.1071 18.7787 11.2455C18.7787 11.1387 18.7763 11.0326 18.7716 10.927C19.2536 10.5786 19.6695 10.1471 20 9.65262Z" fill="#141414"/>
    </svg>

)

export default ShareTwitter
