import React from 'react'

const CloseIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='4.35449' y='26.7947' width='32' height='1' transform='rotate(-45 4.35449 26.7947)' fill='#110000' />
    <rect x='5.06152' y='4' width='32' height='1' transform='rotate(45 5.06152 4)' fill='#110000' />
  </svg>
)

export default CloseIcon
