import { Link } from 'gatsby'
import React, { useState } from 'react'
import Icon from './icons'
import { cn, isExternalUrl } from '../lib/helpers'

import styles from './header.module.css'
import { Image } from './Image'

const Header = ({ onHideNav, onShowNav, onVisibleNav, siteData, showNav, siteTitle, navItems, extNavItems, page, palette }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(0)
  const [hoveredMenuItem, setHoveredMenuItem] = useState(0)
  const [isVisible, setIsVisible] = useState(true)
  // const [scrollPos, setScrollPos] = useState(0)

  // const excludeNavOnPages = ['team', 'companies']

  const selectedMenuItemHandler = (index) => {
    setSelectedMenuItem(index)
    setHoveredMenuItem(1)
  }

  const leaveNavMenuItemHandler = () => {
    setHoveredMenuItem(0)
  }

  const createNavLinks = (navItems) => {
    return navItems.map((link, index) => {
      if (link.blank) {
        return (
          <li key={index} className={hoveredMenuItem === 1 ? index !== selectedMenuItem ? styles.notActive : '' : ''}><a href={link.path} target='_blank' rel='noreferrer' onMouseEnter={() => selectedMenuItemHandler(index)} onMouseLeave={leaveNavMenuItemHandler}>{link.label}</a></li>
        )
      } else if (link.path && link.path.startsWith('http')) {
        return (
          <li key={index} className={hoveredMenuItem === 1 ? index !== selectedMenuItem ? styles.notActive : '' : ''}><a target={isExternalUrl(link.path) ? '_blank' : '_self'} href={link.path} onMouseEnter={() => selectedMenuItemHandler(index)} onMouseLeave={leaveNavMenuItemHandler}>{link.label}</a></li>
        )
      } else {
        return (
          <li key={index} className={hoveredMenuItem === 1 ? index !== selectedMenuItem ? styles.notActive : '' : ''}><Link to={link.path} onClick={index === selectedMenuItem ? onHideNav : () => null} onMouseEnter={() => selectedMenuItemHandler(index)} onMouseLeave={leaveNavMenuItemHandler}>{link.label}</Link></li>
        )
      }
    })
  }

  // useEffect(() => {
  //   const newHandleScroll = () => {
  //     const currentScroll = window.scrollY
  //     if (currentScroll <= 10) {
  //       onVisibleNav(true)
  //       setIsVisible(true)
  //     } else {
  //       if (currentScroll > scrollPos) {
  //         if (!excludeNavOnPages.includes(page)) setIsVisible(false)
  //         if (onVisibleNav !== undefined) onVisibleNav(false)
  //       } else {
  //         if (onVisibleNav !== undefined) onVisibleNav(true)
  //         setIsVisible(true)
  //       }
  //     }
  //     setScrollPos(currentScroll)
  //   }
  //
  //   window.addEventListener('scroll', newHandleScroll)
  //
  //   return () => {
  //     window.removeEventListener('scroll', newHandleScroll)
  //   }
  // }, [scrollPos])

  return (
    <header className={`${cn(styles.root, showNav && styles.navShown, styles[page], styles[palette])} ${isVisible ? styles.visible : styles.hidden}`}>
      <div className={styles.wrapper}>
        <div className={styles.elements}>
          <div className={styles.branding}>
            <Link to='/' onClick={showNav && onHideNav}>{siteTitle}</Link>
          </div>

          <div className={styles.navWrapper}>
            <ul>{extNavItems && createNavLinks(extNavItems)}</ul>

            <button className={styles.toggleNavButton} onClick={showNav ? onHideNav : onShowNav}>
              <Icon symbol='hamburger' />
              <div className={`${showNav && styles.closeBtn}`}><Icon symbol='close' /></div>
            </button>
          </div>

          <nav className={cn(styles.nav, showNav && styles.showNav)}>
            <div className={styles.navGrid}>
              <div className={styles.navScreen}>
                {navItems && navItems.map((link, index) => <Image key={index} imgObj={link.mainImage} props={{ alt: link.title }} className={index === selectedMenuItem ? styles.navItemActive : ''} />)}
              </div>
              <div className={styles.navMain}>
                <div className={styles.navMainContainer}>
                  <ul>{navItems && createNavLinks(navItems)}</ul>
                  <div className={styles.navFooter}>
                    <div className={styles.navCopyright}>&copy; {new Date().getFullYear()} - Redpoint Ventures, all
                      rights reserved
                    </div>
                    <div className={styles.navSocial}>
                      {siteData.socialInstagram && (
                        <Link
                          to={siteData.socialInstagram} className={styles.navSocialItem} target='_blank'
                          rel='noreferrer'
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 25 25'
                            fill='none'
                          >
                            <g clipPath='url(#clip0_2_18)'>
                              <mask
                                id='mask0_2_18' maskUnits='userSpaceOnUse' x='6' y='6'
                                width='13' height='13'
                              >
                                <path d='M19 6H6V19H19V6Z' fill='white' />
                              </mask>
                              <g mask='url(#mask0_2_18)'>
                                <path
                                  d='M15.7959 8.42838C14.7558 8.46023 14.756 9.95666 15.7959 9.98838C16.836 9.95653 16.8358 8.4601 15.7959 8.42838ZM12.5001 9.37998C10.777 9.37998 9.38013 10.7806 9.38013 12.5083C9.54445 16.6579 15.4563 16.6568 15.6201 12.5083C15.6201 10.7806 14.2233 9.37998 12.5001 9.37998ZM12.5001 14.5389C11.3816 14.5389 10.4749 13.6298 10.4749 12.5083C10.5816 9.8147 14.4192 9.81547 14.5254 12.5083C14.5254 13.6297 13.6187 14.5389 12.5001 14.5389Z'
                                  fill='#8D1B1C'
                                />
                                <path
                                  d='M18.6369 9.95471C18.5912 7.78774 17.2126 6.40831 15.0454 6.36307C14.525 6.31289 10.475 6.31185 9.9542 6.36307C7.78749 6.4087 6.40819 7.78735 6.36282 9.95471C6.31264 10.4744 6.3116 14.526 6.36282 15.0461C6.40325 17.2061 7.79425 18.5975 9.9542 18.6378C10.6128 18.6678 10.823 18.675 12.4998 18.675C14.1766 18.675 14.387 18.6678 15.0454 18.6378C15.7026 18.6078 16.1514 18.5034 16.5441 18.3507C17.9205 17.801 18.5929 16.5779 18.6369 15.0461C18.6871 14.5258 18.688 10.4754 18.6369 9.95471ZM14.9947 17.5264C14.4826 17.5753 10.5174 17.5761 10.0049 17.5264C9.403 17.499 9.07605 17.3984 8.85843 17.3139C6.98006 16.4426 7.56246 14.6805 7.47393 12.2619C7.47588 11.7002 7.47276 10.5671 7.47406 10.0054C7.54907 8.33062 8.33778 7.5553 10.0049 7.47431C10.5176 7.4253 14.4819 7.42465 14.9947 7.47431C16.6699 7.54932 17.4444 8.33803 17.5255 10.0053C17.5587 10.4055 17.5827 12.5907 17.5255 13.6966C17.5873 15.7738 17.4927 17.4046 14.9947 17.5264Z'
                                  fill='#8D1B1C'
                                />
                              </g>
                            </g>
                            <defs>
                              <clipPath id='clip0_2_18'>
                                <rect width='13' height='13' fill='white' transform='translate(6 6)' />
                              </clipPath>
                            </defs>
                          </svg>
                        </Link>
                      )}
                      {siteData.socialLinkedin && (
                        <Link
                          to={siteData.socialLinkedin} className={styles.navSocialItem} target='_blank'
                          rel='noreferrer'
                        >
                          <svg
                            width='24' height='24' viewBox='0 0 24 24' fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M5.72129 7.97698C5.40523 7.68352 5.24805 7.32029 5.24805 6.8881C5.24805 6.45591 5.40607 6.07669 5.72129 5.78239C6.03735 5.48894 6.4442 5.3418 6.94267 5.3418C7.44114 5.3418 7.83202 5.48894 8.14724 5.78239C8.4633 6.07585 8.62048 6.44498 8.62048 6.8881C8.62048 7.33122 8.46246 7.68352 8.14724 7.97698C7.83118 8.27043 7.43021 8.41757 6.94267 8.41757C6.45513 8.41757 6.03735 8.27043 5.72129 7.97698ZM8.35486 9.66033V18.6573H5.51282V9.66033H8.35486Z'
                              fill='#8D1B1C'
                            />
                            <path
                              d='M17.8156 10.549C18.4352 11.2217 18.7445 12.145 18.7445 13.3204V18.4983H16.0454V13.6854C16.0454 13.0926 15.8916 12.6318 15.5847 12.3039C15.2779 11.9759 14.8644 11.8111 14.3466 11.8111C13.8287 11.8111 13.4152 11.9751 13.1083 12.3039C12.8015 12.6318 12.6477 13.0926 12.6477 13.6854V18.4983H9.93262V9.63505H12.6477V10.8105C12.9226 10.4187 13.2933 10.1093 13.759 9.88142C14.2247 9.65356 14.7483 9.54004 15.3309 9.54004C16.3682 9.54004 17.197 9.87637 17.8156 10.5482V10.549Z'
                              fill='#8D1B1C'
                            />
                          </svg>
                        </Link>
                      )}
                      {siteData.socialTikTok && (
                        <Link
                          to={siteData.socialTikTok} className={styles.navSocialItem} target='_blank'
                          rel='noreferrer'
                        >
                          <svg
                            width='24' height='24' viewBox='0 0 24 24' fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M17.7966 8.68243V10.9485C17.4007 10.9098 16.8863 10.8198 16.3172 10.6113C15.5741 10.3389 15.021 9.96639 14.6587 9.67798V14.258L14.6495 14.2437C14.6554 14.3345 14.6587 14.427 14.6587 14.5204C14.6587 16.7948 12.8086 18.6463 10.5339 18.6463C8.25932 18.6463 6.40918 16.7948 6.40918 14.5204C6.40918 12.2459 8.25932 10.3935 10.5339 10.3935C10.7567 10.3935 10.9753 10.4112 11.1888 10.4457V12.6789C10.9837 12.6058 10.7634 12.5663 10.5339 12.5663C9.45716 12.5663 8.58042 13.4424 8.58042 14.5204C8.58042 15.5983 9.45716 16.4745 10.5339 16.4745C11.6107 16.4745 12.4875 15.5975 12.4875 14.5204C12.4875 14.48 12.4866 14.4396 12.4841 14.3993V5.49902H14.7478C14.7562 5.69073 14.7638 5.88413 14.7722 6.07584C14.7873 6.45338 14.9218 6.81577 15.1563 7.11259C15.4312 7.46154 15.8372 7.86682 16.4071 8.19054C16.9409 8.4924 17.4419 8.62273 17.7966 8.68411V8.68243Z'
                              fill='#8D1B1C'
                            />
                          </svg>
                        </Link>
                      )}
                      {siteData.socialYoutube && (
                        <Link
                          to={siteData.socialYoutube} className={styles.navSocialItem} target='_blank'
                          rel='noreferrer'
                        >
                          <svg
                            width='24' height='24' viewBox='0 0 24 24' fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M19.4943 9.48633C19.4002 8.57738 19.1976 7.57259 18.452 7.04454C17.8745 6.63505 17.1154 6.61992 16.4068 6.62076C14.9089 6.62076 13.4101 6.62328 11.9122 6.62412C10.4714 6.6258 9.03066 6.62664 7.58989 6.62833C6.98802 6.62833 6.40297 6.58208 5.84398 6.84274C5.364 7.0664 4.98826 7.49187 4.76214 7.96526C4.4486 8.62363 4.38304 9.36945 4.34521 10.0976C4.27545 11.4236 4.28301 12.753 4.36623 14.0781C4.42759 15.0451 4.5831 16.1138 5.33038 16.7301C5.99277 17.2758 6.9275 17.3027 7.78658 17.3036C10.5135 17.3061 13.2412 17.3086 15.9689 17.3103C16.3186 17.3112 16.6834 17.3044 17.0398 17.2657C17.7408 17.1901 18.4091 16.9891 18.8597 16.4695C19.3144 15.9456 19.4313 15.2166 19.5002 14.5263C19.6683 12.8514 19.6666 11.1604 19.4943 9.48633ZM10.3353 14.3136V9.6175L14.4003 11.9651L10.3353 14.3136Z'
                              fill='#8D1B1C'
                            />
                          </svg>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
