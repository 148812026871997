import { graphql, StaticQuery } from 'gatsby'
import React, { useState, useContext } from 'react'
import Helmet from 'react-helmet'
import { themeContext } from '../lib/provider'
import Layout from '../components/layout'

import styles from '../components/globals.module.css'
import { cn } from '../lib/helpers'

const query = graphql`
    query SiteTitleQuery {
        site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
            socialLinkedin
            socialInstagram
            socialTikTok
            socialYoutube
        }
        companyInfo: sanityCompanyInfo(_id: { regex: "/(drafts.|)companyInfo/" }) {
            name
            address1
            address2
            zipCode
            city
            country
        }
        menus: sanityMenus(_id: { regex: "/(drafts.|)menus/" }) {
            hamburgerMenu {
                blank
                label
                path
                mainImage {
                    asset {
                        _id
                    }
                    alt
                }
            }
            externalHeaderMenu {
                blank
                label
                path
            }
            footerMenu {
                blank
                label
                path
                itsLabel
            }
        }
    }
`

function LayoutContainer (props) {
  const [showNav, setShowNav] = useState(false)
  const [visibleNav, setVisibleNav] = useState(true)
  const themeCtx = useContext(themeContext)

  const handleShowNav = () => {
    setShowNav(true)
  }

  const handleHideNav = () => {
    setShowNav(false)
  }

  const handleVisibleNav = (set) => {
    setVisibleNav(set)
  }

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `${cn(((showNav || props.disableScroll) && `${styles.stick} navOpened`), (visibleNav && 'navShown'), props.palette, (('version' in props && props.version === '2') && themeCtx ? (themeCtx.darkMode ? 'dark v2' : 'light v2') : 'light v1'))}`
        }}
        htmlAttributes={{
          class: props.palette
        }}
      />
      <StaticQuery
        query={query}
        render={data => {
          if (!data.site) {
            throw new Error(
              'Missing "Site settings". Open the studio at http://localhost:3333 and add "Site settings" data'
            )
          }
          if (!data.companyInfo) {
            throw new Error(
              'Missing "Company info". Open the studio at http://localhost:3333 and add "Company info" data'
            )
          }
          return (
            <Layout
              {...props}
              showNav={showNav}
              announcement={data.site.announcement}
              headerNavItems={data.menus.hamburgerMenu}
              headerExtNavItems={data.menus.externalHeaderMenu}
              footerNavItems={data.menus.footerMenu}
              siteData={data.site}
              page={props.page}
              companyInfo={data.companyInfo}
              siteTitle={data.companyInfo.name}
              onHideNav={handleHideNav}
              onShowNav={handleShowNav}
              onVisibleNav={handleVisibleNav}
              hideContactBar={!!props.hideContactBar}
              hideAnnouncements={!!props.hideAnnouncements}
              version={props.version}
            />
          )
        }}
      />
    </>
  )
}

export default LayoutContainer
