import React from 'react'

const ShareEmail = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path d="M8 10L13.5 14L19 10" stroke="#141414" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7 17.2492V10.7492C7 10.0312 7.58203 9.44922 8.3 9.44922H18.7C19.418 9.44922 20 10.0312 20 10.7492V17.2492C20 17.9672 19.418 18.5492 18.7 18.5492H8.3C7.58203 18.5492 7 17.9672 7 17.2492Z" stroke="#141414"/>
    </svg>


)

export default ShareEmail
