import React from 'react'

const RightArrowIcon = ({ color = '#B5B0A9' }) => {
  return (
    <svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.6274 12L10.3137 23.3137L8.94629 21.9463L18.0786 12.9442L0.0906677 13.0907L0.18834 10.8116L18.0949 11.1047L8.94629 2.05369L10.3137 0.686279L21.6274 12Z'
        fill={color}
      />
    </svg>
  )
}

export default RightArrowIcon
