import React from 'react'

const PlayCircle = () => {
  return (
    <svg width='138' height='138' viewBox='0 0 138 138' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <circle cx='69' cy='69' r='67' stroke='white' strokeWidth='4' />
      <path d='M59 51L89 68.5L59 86L59 51Z' fill='white' />
    </svg>
  )
}

export default PlayCircle
