import React from 'react'

const Play = () => (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1432 8.348C11.7699 7.95633 11.7699 7.04367 11.1432 6.652L2.03 0.95625C1.36395 0.53997 0.5 1.01881 0.5 1.80425V13.1958C0.5 13.9812 1.36395 14.46 2.03 14.0438L11.1432 8.348Z" fill="currentColor"/>
    </svg>

)

export default Play
