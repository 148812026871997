import React from 'react'
import { Link } from 'gatsby'
import Container from '../container'
import FooterContactForm from './contact-form'

import * as styles from './footer.module.css'
import { isExternalUrl } from '../../lib/helpers'

const Footer = ({ footerNavItems, palette }) => {
  const groupItemsByLabels = (items) => {
    const groups = []
    let currentGroup = []

    items.forEach(item => {
      if (item.itsLabel) {
        if (currentGroup.length) {
          groups.push(currentGroup)
        }
        currentGroup = [item]
      } else {
        currentGroup.push(item)
      }
    })

    if (currentGroup.length) {
      groups.push(currentGroup)
    }

    return groups
  }
  const groupedItems = groupItemsByLabels(footerNavItems)
  return (
    <footer className={`${styles.footer} ${styles[palette]}`}>
      <Container class={styles.footerWrapper} size='large'>
        <div className={styles.middle}>
          <FooterContactForm palette={palette} />

          <div className={styles.menu}>
            {groupedItems.map((group, index) => (
              <div key={index} className={styles.menuBlock}>
                {group.map((link, linkIndex) => {
                  if (linkIndex === 0 && link.itsLabel) {
                    return <span key={linkIndex} className={styles.microTitle}>{link.label}</span>
                  } else if (link.blank || (link.path !== null ? link.path.startsWith('http') : false)) {
                    return (
                      <a key={linkIndex} href={link.path} target='_blank' rel='noreferrer'>{link.label}</a>
                    )
                  } else {
                    return (
                      <Link target={isExternalUrl(link.path) ? '_blank' : '_self'} key={linkIndex} to={link.path}>{link.label}</Link>
                    )
                  }
                })}
              </div>
            ))}
          </div>
        </div>

        {/*<div className={styles.bigSlogan}>We move mountains.</div>*/}

        <div className={styles.bottom}>
          <div className={styles.copyright}>
            &copy; {new Date().getFullYear()} - Redpoint Ventures, all rights reserved
          </div>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
