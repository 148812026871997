import React from 'react'

const ShareFacebook = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="13.5" cy="13.5" r="13.5" fill="white"/>
        <path d="M16.4167 8H14.6667C13.8931 8 13.1512 8.30729 12.6043 8.85427C12.0573 9.40125 11.75 10.1431 11.75 10.9167V12.6667H10V15H11.75V19.6667H14.0833V15H15.8333L16.4167 12.6667H14.0833V10.9167C14.0833 10.762 14.1448 10.6136 14.2542 10.5042C14.3636 10.3948 14.512 10.3333 14.6667 10.3333H16.4167V8Z" fill="#141414"/>
    </svg>

)

export default ShareFacebook
