import React from 'react';

const ArrowIconTopRight = ({color = "#2F2F2F"}) => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1640_233)">
                <path d="M15.4678 4.88913L15.4678 14.8347M15.4678 4.88913L5.52219 4.88913M15.4678 4.88913L5.52219 14.8347" stroke={color} strokeWidth="0.43804" />
            </g>
            <defs>
                <clipPath id="clip0_1640_233">
                    <rect width="14.8934" height="14.8934" fill="white" transform="matrix(-0.707107 0.707107 0.707107 0.707107 10.5312 0)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default ArrowIconTopRight