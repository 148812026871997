import { format } from 'date-fns'
import { useState, useEffect } from 'react'

export function cn (...args) {
  return args.filter(Boolean).join(' ')
}

export function mapEdgesToNodes (data) {
  if (!data.edges) return []
  return data.edges.map(edge => edge.node)
}

export function filterOutDocsWithoutSlugs ({ slug }) {
  return (slug || {}).current
}

export function getNewsUrl (publishedAt, slug) {
  return `/news/${format(publishedAt, 'YYYY/MM')}/${slug?.current || slug}/`
}

export function buildImageObj (source) {
  const imageObj = {}
  if (source.asset) imageObj.asset = { _ref: source.asset._ref || source.asset._id }
  if (source.crop) imageObj.crop = source.crop
  if (source.hotspot) imageObj.hotspot = source.hotspot

  return imageObj
}

export function readTime (content) {
  const foundImages = (content.match(/<img/g) || []).length
  const text = content.replace(/(<([^>]+)>)/gi, '')
  const foundWords = text.split(' ').length

  let secs = (foundWords / 275) * 60
  let addSecs = 12
  for (let i = 0; i < foundImages; i++) {
    secs += addSecs
    addSecs--
    if (addSecs < 3) addSecs = 3
  }
  const minutes = Math.ceil(secs / 60)

  return minutes
}

export function calculateRawContent (_rawContent) {
  const content = _rawContent ? _rawContent.map(block => {
    if (block._type === 'block' && block.children) {
      return block.children.map(child => child.text).join(' ')
    }
    return ''
  }).join(' ') : ''

  if (!content) return 0

  const wordsPerMinute = 200
  const words = content.split(/\s+/).length
  const totalSeconds = Math.ceil((words / wordsPerMinute) * 60)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  // return (minutes > 1 ? minutes + ' minute' : '') + ' ' + (seconds > 1 ? seconds + ' seconds' : '') + ' read'
  return (minutes > 1 ? minutes + ' minute' : (seconds > 1 ? seconds + ' second' : '')) + ' read'
}

export function useWindowDimensions () {
  function getWindowDimensions () {
    if (typeof window !== 'undefined') {
      const { innerWidth: width, innerHeight: height } = window
      return {
        width,
        height
      }
    }

    return { width: 0, height: 0 }
  }

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize () {
      setWindowDimensions(getWindowDimensions())
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowDimensions
}

export function debounce (func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func.apply(this, args)
    }, delay)
  }
}

export function throttle (func, delay) {
  let lastCall = 0
  return (...args) => {
    const now = new Date().getTime()
    if (now - lastCall < delay) {
      return
    }
    lastCall = now
    return func(...args)
  }
}

export const isExternalUrl = (url) => {
  if (typeof window !== 'undefined') {
    const domain = (url) => url.replace('http://', '').replace('https://', '').split('/')[0]
    return domain(window.location.href) !== domain(url)
  }
  return false
}
